import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import Card from '@/components/Card/Card'

const Hero = ({ data, main, children, fluid, heading }) => {
  return (
    <section className={main ? 'hero hero__main' : 'hero hero__std'}>
      <BackgroundImage
        style={{ position: 'absolute' }}
        fluid={fluid}
        className='hero__background'
      />
      <div className='container-sm'>
        {main ? (
          <div className='flex lg:flex-row flex-col justify-between items-end '>
            <Card
              tel
              source={data.phone}
              link={`tel:${data.phone}`}
              buttonWidth={'130px'}
              heading='Call us'
              buttonText='Direct call'
              src='/img/svg/icon_phone_2.svg'
            />
            <Card
              source={data.booking}
              buttonWidth={'147px'}
              heading='Book a visit'
              link={'#onlinebookings'}
              main
              buttonText='Book a visit'
              src='/img/svg/icon_book.svg'
            />
            <Card
              link={
                'https://www.google.com/maps/place/37+Kennedy+St,+Kingston+ACT+2604,+Australia/@-35.3166976,149.1413496,17z/data=!4m2!3m1!1s0x6b164cfb7f251ca9:0x57f63aa6ef50233f'
              }
              newcard={true}
              source={data.adress}
              buttonWidth={'147px'}
              heading='Find our Salon'
              buttonText='Google maps'
              src='/img/svg/icon_location.svg'
            />
          </div>
        ) : (
          <h1>{heading}</h1>
        )}
      </div>
    </section>
  )
}

export default Hero
