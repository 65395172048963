import React from 'react'
import ReactSVG from 'react-svg'
import ReactMarkdown from 'react-markdown'

const Card = ({
  src,
  source,
  heading,
  buttonText,
  main,
  buttonWidth,
  link,
  tel,
  newcard,
}) => {
  return (
    <div className={main ? 'card card__main' : 'card card__secondary'}>
      <div className='card__inner'>
        <ReactSVG className='mx-auto flex justify-center' src={src} />
        {main ? (
          <h2 className='text-center'>{heading}</h2>
        ) : (
          <h4 className='text-center'>{heading}</h4>
        )}
        <ReactMarkdown className='text-center' source={source}></ReactMarkdown>
        <a
          href={link}
          rel={newcard && 'noopener noreferrer'}
          target={newcard && '_blank'}
          className={
            main ? 'btn btn__green btn--l' : 'btn btn__transparent btn--m'
          }
          style={{ maxWidth: { buttonWidth } }}>
          {buttonText}
          <span className='btn__arrow'></span>
        </a>
      </div>
    </div>
  )
}

export default Card
