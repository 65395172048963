import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import SEO from '@/components/SEO/Seo'

import Layout from '@/components/Layout'
import Hero from '@/components/Hero/Hero'

const NewsPageTemplate = ({ data }) => {
  return (
    <>
      <Hero
        heading={data.heading}
        fluid={data.background.childImageSharp.fluid}
      />
      <div className='flex items-center justify-center py-20'>
        <div className='container'>
          <div className='twitter-feed text-black'>
            <a
              className='twitter-timeline'
              href='https://twitter.com/ShiHair?ref_src=twsrc%5Etfw'>
              Tweets by ShiHair
            </a>{' '}
          </div>
        </div>
      </div>
    </>
  )
}

const NewsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <Layout hideNav={true}>
      <SEO data={frontmatter.seo} />
      <NewsPageTemplate data={frontmatter} />
    </Layout>
  )
}

NewsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export const pageQuery = graphql`
  query NewsPageTemplate {
    markdownRemark(frontmatter: { template: { eq: "news" } }) {
      frontmatter {
        title
        heading
        background {
          childImageSharp {
            fluid(quality: 45, maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        ...SEO
      }
    }
  }
`

export default NewsPage
